<template>
  <div
    class="displayBetween"
    style="height: 100%; width: 100%"
    id="sd_rightOne"
  ></div>
</template>

<script>
import myEchartFn from "@/utils/echarts/index";
import {zhuzi} from '@/utils/echarts/shudi/zhuzi'
import {misreTimeDistribution} from '@/api/daping/index'
export default {
  data() {
    return {
      autoTime: null,
      
    };
  },
  methods: {
    async getInfo(dateType = 1) {
      let Res = await misreTimeDistribution({period: dateType});
      let ResData = Res?.data?.data.map(v=> {
        return {
          name: v.date,
          value: v.total
        }
      }) || [];
      this.setEcharts("sd_rightOne", ResData,dateType);
    },
    setEcharts(domName, ResData) {
      if(this.autoTime) {
        clearInterval(this.autoTime)
        this.autoTime = null
      }

      let myChart = myEchartFn.getMyChartok(domName);
      
      if (!ResData || ResData.length == 0) {
        myChart.setOption(myEchartFn.kongOption());
        return;
      }
      let { dataX, dataArr, dataX2, dataArr2 } = myEchartFn.getSwiperItem(
        ResData,
        4
      );
      let option = zhuzi(dataX, dataArr);
      myChart.setOption(option);
      this.autoTime = setInterval(() => {
        myEchartFn.autoMove(
          { dataX, dataArr, dataX2, dataArr2 },
          option,
          myChart,
          this.autoTime,
        );
      }, 3000);
    },
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {
    clearInterval(this.autoTime);
  },
};
</script>

<style></style>
