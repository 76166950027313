<template>
  <div
    class="displayBetween"
    style="height: 100%; width: 100%"
    id="sd_leftOne"
  ></div>
</template>

<script>
import { getAbnormalNum } from "@/api/index/index";
import { getMyChartok } from "@/utils/echarts";
export default {
  data() {
    return {};
  },
  methods: {
    async getInfo() {
      let Res = await getAbnormalNum({
        aggField: "body_type",
        dictField: "body_type",
      });
      let ResData = Res?.data?.data.filter(v=>v.name) || [];
      this.getEcharts("sd_leftOne", ResData);
    },
    getEcharts(domName, ResData) {
      let myChart = getMyChartok(domName);
      var color = [
        "#8d7fec",
        "#5085f2",
        "#e75fc3",
        "#f87be2",
        "#f2719a",
        "#fca4bb",
        "#f59a8f",
        "#fdb301",
        "#57e7ec",
        "#cf9ef1",
      ];

      let option = {
        // backgroundColor: "rgba(255,255,255,1)",
        color: color,
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: "rgba(0,0,0,.9)",
          borderColor: "rgba(147, 235, 248, 0)",
          textStyle: {
            color: "#FFF",
          },
          confine: true,
          formatter: "{b}<br/>{c}<br/>{d}%"
        },
        legend: {
          orient: "vartical",
          type: "scroll",
          x: "left",
          top: "center",
          left: "5%",
          bottom: "0%",
          data: ResData.map((v) => v.name),
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 16,
          textStyle: {
            color: "#fff",
          },
          formatter: function(name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            radius: ["25%", "55%"],
            center: ["60%", "50%"],
            // avoidLabelOverlap: false,//是否启用防止标签重叠策略
            minAngle: 2,//扇区最小值
            minShowLabelAngle: 3,//小于这个角度（0 ~ 360）的扇区，不显示标签（label 和 labelLine）
            itemStyle: {
              //图形样式
            },
            label: {
              normal: {
                show: true,
                position: "outside",
                formatter: "{text|{b}}\n {value|{c}}{per|({d}%)} ",
                rich: {
                  text: {
                    color: "#fff",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#CAD9F5",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                  },
                  per: {
                    color: "#CAD9F5",
                    fontSize: 12,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              labelLine: {
                normal: {
                  show: true,
                  lineStyle: {
                    color: "#fff",
                  },
                },
              },
              // emphasis: {
              //   show: false,
              //   textStyle: {
              //     fontSize: 14,
              //   },
              // },
            },
            data: ResData,
          },
        ],
      };
      myChart.setOption(option);

      // setTimeout(function() {
      //   myChart.on("mouseover", function(params) {
      //     if (params.name == ydata[0].name) {
      //       myChart.dispatchAction({
      //         type: "highlight",
      //         seriesIndex: 0,
      //         dataIndex: 0,
      //       });
      //     } else {
      //       myChart.dispatchAction({
      //         type: "downplay",
      //         seriesIndex: 0,
      //         dataIndex: 0,
      //       });
      //     }
      //   });

      //   myChart.on("mouseout", function(params) {
      //     myChart.dispatchAction({
      //       type: "highlight",
      //       seriesIndex: 0,
      //       dataIndex: 0,
      //     });
      //   });
      //   myChart.dispatchAction({
      //     type: "highlight",
      //     seriesIndex: 0,
      //     dataIndex: 0,
      //   });
      // }, 1000);
    },
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {},
};
</script>

<style></style>
