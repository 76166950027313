import myEchartFn from "../index";
export function yuanhuan2(domName, ResData, { myChart, timeCom }) {
  if (timeCom) {
    clearInterval(timeCom);
    timeCom = null;
  }
  let chartData = ResData.map((v) => ({ name: v.name, value: Number(v.value) }));
  myChart = myEchartFn.getMyNewChart(domName, myChart);
  if (!chartData || chartData.length == 0) {
    myChart.setOption(myEchartFn.kongOption());
    return;
  }
  const colorList = [
    "#1399FF",
    "#18DBFF",
    "#77FEC4",
    "#FF9731",
    "#F7575B",
    "#9999FE",
    "#E769FA",
    "#FDFF61",
    "#00B3CE",
    "#4347EF",
    "#63F2FF",
    "#9999FE",
  ];
  let centerOrtop = () => {
    if (ResData.legend > 9) {
      return "10%";
    } else {
      return "center";
    }
  };
  const sum = chartData.reduce((per, cur) => per + cur.value, 0);
  const gap = (1 * sum) / 100;
  const pieData1 = [];
  const pieData2 = [];
  const gapData = {
    name: "",
    value: gap,
    itemStyle: {
      color: "transparent",
    },
  };

  //图标位置显示
  let legendData = [];
  let total = 0;
  chartData.forEach((item) => {
    total += item.value;
  });

  for (let i = 0; i < chartData.length; i++) {
    // 第一圈数据
    pieData1.push({
      ...chartData[i],
      itemStyle: {
        borderRadius: 10,
      },
    });
    pieData1.push(gapData);

    // 第二圈数据
    pieData2.push({
      ...chartData[i],
      itemStyle: {
        color: colorList[i],
        opacity: 0.21,
      },
    });
    pieData2.push(gapData);

    //  分散图例
    let bfb = parseInt((chartData[i].value / total) * 100) + "%";
    legendData.push({
      show: true,
      left: 0,
      top: 0,
      data: [chartData[i].name],
    });
  }
  // console.log("pieData2---------", pieData2);

  let option = {
    // backgroundColor: '#0F141B',
    title: {
      x: "29%",
      y: "40%",
      // itemGap: 15,
      textStyle: {
        color: "#f5f5f6",
        fontSize: 18,
        // fontWeight: "bold",
      },
      subtextStyle: {
        color: "#f5f5f6",
        fontSize: 14,
        fontWeight: "bold",
      },
      textAlign: "center",
    },

    // backgroundColor: "#0F141B",
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0,.8)",
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
      type: "scroll",
      show: true, // 是否显示图例
      orient: "vertical", // 图例排列方向
      icon: "circle", // 图例样式为圆形
      itemWidth: 6, // 图例图形的宽度
      // itemHeight: 165, // 图例图形的高度
      itemGap: 10, // 图例项之间的间隔
      right: "13%", // 图例距离容器右侧的距离
      top: centerOrtop(), // 图例垂直居中

      textStyle: {
        // width:150,
        color: "#C6DAFF", // 图例文字颜色
        fontSize: 14,
        //    overflow:'break'
      },
      tooltip: {
        show: true,
      },
      data: chartData,
      formatter: (params) => myEchartFn.axisLabelFormatter(params, 7),
    },
    grid: {
      //   top: 30,
      right: 20,
      // bottom: 30,
      left: -10,
    },
    color: colorList,
    series: [
      {
        name: "",
        type: "pie",
        roundCap: true,
        radius: ["66%", "70%"],
        center: ["30%", "50%"],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: pieData1,
      },
      {
        type: "pie",
        radius: ["66%", "60%"],
        center: ["30%", "50%"],
        gap: 1.71,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: pieData2,
      },
      {
        type: "gauge",
        zlevel: 2,
        splitNumber: 90,
        radius: "70%",
        center: ["30%", "50%"],
        startAngle: 90,
        endAngle: -269.9999,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: true,
          length: 4,
          lineStyle: {
            width: 4,
            color: "rgb(33,85,130)",
          },
        },
        pointer: {
          show: 0,
        },
        detail: {
          show: 0,
        },
      },
      {
        type: "pie",
        center: ["30%", "50%"],
        radius: [0, "45.6%"],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        itemStyle: {
          color: "rgba(75, 126, 203,.1)",
        },
        silent: true,
        data: [
          {
            value: 100,
            name: "",
          },
        ],
      },
    ],
  };
  let optionData = option.series[0].data;
  let all = optionData.reduce((p, v) => (p = p + v.value), 0);

  const my_fn = ({ currentIndex }) => {
    // console.log(currentIndex)
    let percent = 0;
    if (!all || all == 0) {
      percent = 0;
    } else {
      percent = ((optionData[currentIndex].value / all) * 100).toFixed(2) || 0;
    }
    option.title.text = myEchartFn.axisLabelFormatter(
      optionData[currentIndex].name,
      7
    );
    option.title.subtext =   `数量 ${optionData[currentIndex].value}\n占比 ${percent}%`;
    myChart.setOption(option);
  };
  // 自动轮播功能
  handleChartLoop(option, myChart, my_fn);
  function handleChartLoop(option, myChart) {
    if (!myChart) return;
    let currentIndex = -1; // 当前高亮图形在饼图数据中的下标
    // 取消所有高亮并高亮当前图形
    function highlightPie() {
      // 遍历饼图数据，取消所有图形的高亮效果
      if (currentIndex % 2 !== 0) return;
      for (var idx in optionData) {
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: idx,
        });
      }
      // 高亮当前图形
      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      if (my_fn) my_fn({ currentIndex });
    }

    // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
    myChart.on("mouseover", (params) => {
      if (!params.name) return;
      // clearInterval(timeCom);
      // myEchartFn.myClearTimer('shudi_yuanhuan2')
      currentIndex = params.dataIndex;
      highlightPie();
    });

    // 用户鼠标移出时，重新开始自动切换
    // myChart.on("mouseout", (params) => {
    //    if(!params.name) return
    //   myEchartFn.mySetTimer('shudi_yuanhuan2',selectPie, 1000)
    // });

    // 高亮效果切换到下一个图形
    // pubsub.subscribe('toumingBingTu',() => {
    timeCom = setInterval(() => {
      selectPie();
    }, 3000);
    // selectPie()
    //  })
    function selectPie() {
      var dataLen = optionData.length;
      currentIndex = (currentIndex + 1) % dataLen;
      highlightPie();
    }
  }
  myChart.setOption(option);
  my_fn({ currentIndex: 0 });
  myChart.dispatchAction({
    type: "highlight",
    seriesIndex: 0,
    dataIndex: 0,
  });
  return {
    myChart,
    timeCom,
  };
}
export default {
  yuanhuan2,
};
