<template>
  <div class="content">
    <div class="list">
      <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
        <div class="list-item flex-b cur" v-for="(item, index) in list" :key="index" @click="$main.routeResolve('/questionDetail',{item})">
          <img src="@/assets/img/views/shudi/jinggao.png" class="problemImg" />
          <el-tooltip popper-class="atooltip" effect="dark" :content="item.accountName" :disabled='item.accountName.length<7' placement="right-start">
            <div class="problemName text-c overflowText">{{ item.accountName }}</div>
          </el-tooltip>
          <div class="problemType text-c overflowText">{{ item.messageTypeNameList.join() }}</div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { queryPage } from '@/api/platformaccount/index'
export default {
  data() {
    return {
      list: [
      ],
    };
  },
  methods: {
    // 问题平台
    async getInfo() {
      let Res = await queryPage({
        pageNo: 1,
        pageSize: 10,
        disposalLogo: 0,
        statHarm: true,
        abnormal: true,
        sort: {}
      });
      this.list = Res?.data?.data?.list || []
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0.2353rem 0.2824rem;
  overflow: hidden;
  .list {
    .list-item {
      width: 100%;
      height: 0.47rem;
      line-height: 0.47rem;
      margin-bottom: 0.2941rem;

      .problemImg {
        width: 0.5529rem;
        height: 0.5412rem;
      }
      .problemName {
        width: 35%;
        height: 100%;
        font-size: 0.18rem;
        color: #fefeff;
      }
      .problemType {
        width: 30%;
        height: 100%;
        font-size: 0.16rem;
        color: #54f9ff;
      }
    }
  }
}
</style>
<style>
.atooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 2.9412rem !important;
}
.atooltip .popper__arrow {
  display: none;
}
</style>