<!--
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2023-11-16 18:04:25
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-08-21 17:49:27
 * @FilePath: \属地三期\src\views\daping\shudiRightThree.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    <div class="list-title flex-b">
      <span>行动名称</span>
      <span>开始时间</span>
      <span>匹配数据量</span>
      <span>较昨日</span>
    </div>
    <div class="list">
      <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
        <div class="list-item flex-b" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <el-tooltip popper-class="btooltip" effect="dark" :content="item.name"  placement="right-start">
            <span class="TextHieedn cur">{{ item.name }}</span>
          </el-tooltip>
          <el-tooltip popper-class="btooltip" effect="dark" :content="item.startTime" :disabled='item.startTime.length<7' placement="right-start">
            <span class="TextHieedn cur">{{ item.startTime }}</span>
          </el-tooltip>
          <span>{{ item.count }}</span>
          <!-- <span style="color:#FF3057;font-size:16px;"> <img style="width:10px" src="@/assets/img/views/shudi/xiajt.png" alt="" srcset=""> {{ item.yesterdayCount }}</span> -->
          <span v-if="item.flag == -1" style="color:#FF3057;font-size:16px;"> <img style="width:10px" src="@/assets/img/views/shudi/xiajt.png" alt="" srcset="">
            {{ item.todayCount }}</span>
          <span v-else style="color:#54F9FF;font-size:16px;"> <img style="width:10px" src="@/assets/img/views/shudi/shangjt.png" alt="" srcset="">
            {{ item.todayCount }}</span>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { planSpecialList } from '@/api/daping/index'
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async getInfo() {
      let Res = await planSpecialList({
        limit: 10, page: 1
      })
      this.list = Res?.data?.data || []
    },
    goDetail(item){
      let routeData = this.$router.resolve({
        path: "/topicListDetails",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: item.name,
              item: item,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
    }
  },
  created() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0.1412rem 0.1882rem;
  overflow: hidden;
  .list-title {
    width: 100%;
    height: 0.3765rem;
    background: #0f1e46;
    //   opacity: 0.08;
    margin-bottom: 0.2588rem;
    color: #d0e0ff;
    font-size: 0.1647rem;
    line-height: 0.3765rem;
    text-align: center;
    span {
      width: 20%;
    }
  }
  .list {
    overflow: hidden;
    .list-item {
      text-align: center;
      font-size: 0.1647rem;
      color: #bacef4;
      line-height: 0.3529rem;
      margin-bottom: 0.2588rem;
      span {
        width: 20%;
      }
    }
  }
}
</style>
